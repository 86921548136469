import {
  AbortError,
  BodyReadError,
  EmptyBodyError,
  FetchError,
  HttpError,
} from './errors'

export class StreamFetch {
  urlBase = 'https://firebaseremoteconfigrealtime.googleapis.com'
  url: string

  constructor(
    public firebaseInstallations: {
      getId(): Promise<string>
      getToken(forceRefresh?: boolean): Promise<string>
    },
    public sdkVersion: string,
    public namespace: string,
    public projectId: string,
    public apiKey: string,
    public appId: string,
    public getLastKnownVersion: () => Promise<string | undefined>
  ) {
    this.url = `${this.urlBase}/v1/projects/${projectId}/namespaces/${namespace}:streamFetchInvalidations?key=${apiKey}`
  }

  async *fetch(signal: AbortSignal, version?: string) {
    const installationId = await this.firebaseInstallations.getId()
    // const [installationId, installationToken] = await Promise.all([
    //   this.firebaseInstallations.getId(),
    //   this.firebaseInstallations.getToken(),
    // ])

    const body = {
      sdk_version: this.sdkVersion,
      app_instance_id: installationId,
      // app_instance_id_token: installationToken, // looks like not required and causes error
      app_id: this.appId,
      last_known_version_number: version,
      project: this.projectId,
      namespace: this.namespace,
    }

    let request: Request | undefined
    let response: Response | undefined

    // create request
    // can throw `TypeError`
    try {
      request = new Request(this.url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Content-Encoding': 'gzip',
        },
        body: JSON.stringify(body),
        signal,
      })
    } catch (error) {
      throw new FetchError((error as Error)?.message, error)
    }

    if (request == null) {
      throw new FetchError('Request was not created')
    }

    // fetch response
    // can throw `AbortError(DOMException)`, `NotAllowedError(DOMException)` or `TypeError`
    try {
      response = await fetch(request)
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new AbortError('Request aborted', error)
      } else {
        throw new FetchError((error as Error)?.message, error)
      }
    }

    if (response == null) {
      throw new FetchError('Response was not received')
    }

    if (response.status !== 200) {
      throw new HttpError(response)
    }

    if (response.body == null) {
      throw new EmptyBodyError(response)
    }

    // read body stream and yield string chunks
    try {
      let reader = response.body.getReader()
      while (true) {
        const { done, value } = await reader.read()
        yield new TextDecoder().decode(value)
        if (done) break
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new AbortError('Body read aborted', error)
      } else {
        throw new BodyReadError((error as Error)?.message, error)
      }
    }
  }
}
