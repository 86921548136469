import { register } from '!/repository'
import { model as router } from '!/router'
import { model as sse } from '!/sse'
import { model as config, remote } from '@/config'
import { noop } from '@/effector'
import { F, T, noop as noopFn } from '@/helpers'
import { api } from '@setplex/tria-api'
import {
  attach,
  combine,
  createEvent,
  createStore,
  sample,
  split,
  type Store,
} from 'effector'
import { persist } from 'effector-storage/local'
import { debounce, not } from 'patronum'

export const init = createEvent()

// get account effect
export const getAccountFx = attach({ effect: api.session.getAccountFx })

// session store
export const $session = api.session.$session
// store for a flag, indicating, that session has been restored
export const $ready = createStore<boolean>(false)

// event to separate regular account request failure from TOA `EC16`
const failedOrToa = createEvent<boolean>()

// authentication flag
export const $authenticated = createStore<boolean>(false)
  .on($session, (__, session) => Boolean(session))
  .on(failedOrToa, (__, x) => x) // reset authentication flag on any request account error (except TOA)
  .on(api.session.signOutFx.finally, F) // in case of sign out
  .on(api.events.http.unauthorized, F) // in case of any 401 error -> reset authentication flag

// negative authentication flag
export const $notAuthenticated: Store<boolean> = not($authenticated)

// expired subscription flag
export const $expired = createStore<boolean>(false)
  .on($session, (__, session) => session?.currentSubscriptionActive === false)
  .on(
    api.session.signInFx.doneData,
    (__, { payload }) => payload.currentSubscriptionActive === false
  )
  .on(api.events.http.unauthorized, F) // reset expired flag in case of any 401 error

// TOA accepted flag
export const $toaAccepted = createStore<boolean>(false)
  .on($session, (__, session) => session?.toa === null)
  .on(api.session.signInFx.doneData, (__, { payload }) => payload.toa === null)
  .on(api.events.logic.TOANotAccepted, F) // reset TOA flag on TOA not accepted error
  .on(api.events.http.unauthorized, F) // reset TOA flag in case of any 401 error
  .on(api.session.guestInFx.done, T) // TOA for guest is always like "accepted"

// TOA accepted flag
export const $hasToa = createStore<boolean>(false)
  .on(
    $session,
    (__, session) =>
      Boolean(session?.toaActive) &&
      session?.currentSubscriptionActive !== false
  )
  .on(
    api.session.signInFx.doneData,
    (__, { payload }) =>
      Boolean(payload.toaActive) && payload.currentSubscriptionActive !== false
  )
  .on(api.session.guestInFx.doneData, (__, { payload }) =>
    Boolean(payload.toaActive)
  )

// account number, will change on re-authenticate,
// or, for example, when change authentication from guest to subscriber
export const $accountNumber = createStore<string | null>(null).on(
  $session,
  (__, session) => session?.accountNumber ?? null
)

// save authentication flag in localStorage
// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: $authenticated,
  key: 'tria__signed-in',
  sync: false, // do not synchronize this flag across tabs
  fail: noop,
})

// save account number in localStorage
// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: $accountNumber,
  key: 'tria__account-number',
  fail: noop,
})

// in case of EC16 errorCode -> consider authentication goes right
sample({
  clock: getAccountFx.failData,
  fn: (data) => data?.payload?.errorCode === 'EC16', // FIXME: hardcoded codes
  target: failedOrToa,
})

// in case of previously authenticated -> try to get account
// this will reset authentication flag in case of 401 response
sample({
  clock: init,
  filter: $authenticated,
  fn: () => ({ refresh: true }),
  target: getAccountFx,
})

// in case of NOT authenticated -> set session is ready right away
sample({
  clock: init,
  filter: $notAuthenticated,
  fn: T,
  target: $ready,
})

// on any response from getAccountFx -> set session is ready
sample({
  clock: getAccountFx.finally,
  fn: T,
  target: $ready,
})

// in case account number was changed (asynchronously from localStorage)
//   -> if changed to `null` - reset session
//   -> re-request account (and subscriber later)
//   -> and refresh current route (because account changing can cause many side-effects)

const accountNumberChanged = debounce({ source: $accountNumber, timeout: 500 })
const toaAcceptedChanged = debounce({ source: $toaAccepted, timeout: 500 })

sample({
  clock: accountNumberChanged,
  source: $session,
  filter: (session, accountNumber) =>
    accountNumber == null && //
    session?.accountNumber != null,
  target: api.session.reset,
})

sample({
  clock: [accountNumberChanged, toaAcceptedChanged],
  source: {
    session: $session,
    accountNumber: $accountNumber,
    toaAccepted: $toaAccepted,
  },
  filter: ({ session, toaAccepted, accountNumber }) =>
    accountNumber != null && //
    accountNumber !== session?.accountNumber &&
    toaAccepted,
  fn: noopFn,
  target: [getAccountFx, router.refresh],
})

//
// connect, disconnect or reconnect to SSE
//

const updateSSEConnection = createEvent()

sample({
  clock: $session,
  filter: config.get(remote.tria_isServerSentEventsEnabled),
  target: [sse.reset, updateSSEConnection],
})

split({
  source: updateSSEConnection,
  match: combine($authenticated, $session, (authenticated, session) =>
    authenticated && session?.profileId && session?.profileId > 0
      ? 'signedIn'
      : 'signedOut'
  ),
  cases: {
    signedIn: sse.connect,
    signedOut: sse.close,
  },
})

//
// register stores and events
//

register($session, '$entity/session')
