import { model as config, remote } from '@/config'
import { createEffect, createEvent, createStore, sample } from 'effector'
import NpawPluginAdapters from 'npaw-plugin-adapters'
import NpawPlugin from 'npaw-plugin-nwf'
import * as v from 'valibot'
import { model as session } from '~/entities/session'
import type { NpawPlayerOptions } from '~/features/npaw/index.h'
import { aye, nay } from '~/shared/helpers'
import { logger } from '~/shared/logger'
import { createNpawAnalyticsOptions } from './helpers'
import { NpawConfigSchema, type NpawConfig } from './schemas'
// import 'npaw-plugin-p2p'
import type { VideoJsPlayer } from '@setplex/player'
import { LogLevel, type IVideo } from './index.h'

export const init = createEvent()

//
// NPAW configuration
//

const $stringifiedConfig = config.get(remote.tria_playerNpawPluginConfig)
const $config = createStore<NpawConfig | null>(null)

const parseConfigFx = createEffect((config?: string | null) => {
  return typeof config === 'string' && config !== ''
    ? v.parse(NpawConfigSchema, JSON.parse(config))
    : null
})

sample({
  clock: [init, $stringifiedConfig],
  source: $stringifiedConfig,
  target: parseConfigFx,
})

sample({
  clock: parseConfigFx.doneData,
  target: $config,
})

sample({
  clock: parseConfigFx.fail,
  fn: (fail) => ['fail to parse npaw configuration:', fail],
  target: logger.errorFx,
})

//
// NPAW plugin configuration
// combined with default NPAW data
// for the player
//

export const $playerNpaw = createStore<NpawPlayerOptions | null>(null)

// update on every session or config change
sample({
  source: {
    session: session.$session,
    config: $config,
  },
  filter: ({ config, session }) =>
    aye(config) && aye(session) && config!.enabled,
  fn: ({ config, session }) => {
    const { balancerOptions, accountCode } = config!
    return {
      accountCode,
      balancerOptions,
      analyticsOptions: createNpawAnalyticsOptions(session!),
    } satisfies NpawPlayerOptions
  },
  target: $playerNpaw,
})

// reset on empty config or empty session
sample({
  source: {
    session: session.$session,
    config: $config,
  },
  filter: ({ config, session }) =>
    nay(config) || nay(session) || nay(config!.enabled),
  target: $playerNpaw.reinit,
})

// npaw plugin log level
// need to refactor, to be able to actually set it
// TODO: https://setplexapps.atlassian.net/browse/SAC-94
let logLevel: LogLevel = LogLevel.ERROR
export function setLogLevel(level: LogLevel) {
  logLevel = level
}

// generate unique key for each video player
// https://documentation.npaw.com/integration-docs/docs/multiple-video-players-js
let key = 0
function videoKey() {
  return `media_${key++}`
}

// npaw plugin instance, must be a singleton
let plugin: NpawPlugin | null = null

export function initNpawPlugin(
  player: VideoJsPlayer,
  npawData: NpawPlayerOptions,
  video: IVideo
) {
  const {
    accountCode,
    pluginOptions,
    balancerOptions,
    analyticsOptions,
    videoOptions,
  } = npawData

  if (!accountCode) return null

  // create plugin instance if not exists
  plugin = plugin || new NpawPlugin(accountCode, pluginOptions)

  // set balancer options
  // https://documentation.npaw.com/integration-docs/docs/options-cdnbalancer-js
  if (balancerOptions) {
    const balancerOptionsCombined = {
      ...balancerOptions,
    }

    plugin.setBalancerOptions(balancerOptionsCombined)
    logger.info('NPAW BALANCER OPTIONS', balancerOptionsCombined)
  }

  // set analytics options
  // https://documentation.npaw.com/integration-docs/docs/options-analytics-js
  if (analyticsOptions) {
    const analyticsOptionsCombined = {
      ...analyticsOptions,
    }

    plugin.setAnalyticsOptions(analyticsOptionsCombined)
    logger.info('NPAW ANALYTICS OPTIONS', analyticsOptionsCombined)
  }

  // video options
  // https://documentation.npaw.com/integration-docs/docs/options-video-js
  const videoOptionsCombined = {
    ...videoOptions,
    'content.resource': video.sourceUrl,
    'content.drm': video.drm ? Object.keys(video.drm.la).join(',') : undefined, // JSON.stringify(video.drm),
  }
  logger.info('NPAW VIDEO OPTIONS', videoOptionsCombined)

  // register adapter with setting video options
  plugin.registerAdapterFromClass(
    player,
    NpawPluginAdapters.video.Videojs,
    videoOptionsCombined,
    videoKey()
  )

  // set log level
  plugin.setLogLevel(logLevel)

  return plugin
}
