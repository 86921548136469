import { model as i18n } from '!/i18n'
import { noop } from '@/effector'
import { aye, exists, nay } from '@/helpers'

import { model as config, remote } from '@/config'
import {
  api,
  type IGenerateSeriesPlaylistParams,
  type SeriesPlaylistRecalculate,
} from '@setplex/tria-api'

import { combine, createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import type { IInitPlayerPageShowPlayingParams } from './interfaces'
import { playlistFormatter } from './lib'

export const $recommendedCoverEnabled = config.get(
  remote.tria_isRecommendedCoverEnabled
)

export const initPlayerPageShowPlaying =
  createEvent<IInitPlayerPageShowPlayingParams>()

sample({
  clock: initPlayerPageShowPlaying,
  fn: (clockData) => ({
    categoryId: Number(clockData.categoryId),
    tvShowId: Number(clockData.tvShowId),
    showIndex: exists(clockData.showIndex)
      ? Number(clockData.showIndex)
      : undefined,
  }),
  target: api.tvshows.getRecommendedTvShowsFx,
})

// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: api.tvshows.$seriesPlaylist,
  key: 'tria__series-playlist',
  fail: noop,
})

sample({
  clock: initPlayerPageShowPlaying,
  source: {
    playlist: api.tvshows.$seriesPlaylist,
  },
  filter: ({ playlist }) => nay(playlist?.nextEpisode),
  fn: (_, clockData) => {
    const params: IGenerateSeriesPlaylistParams = {
      tvShowId: clockData.tvShowId,
      seasonId: clockData.seasonId,
      episodeId: clockData.episodeId,
      categoryId: clockData.categoryId,
      showIndex: clockData.showIndex,
      isLooped: false, // default value
      isAutoplay: false, // default value
    }

    return params
  },
  target: api.tvshows.generateSeriesPlaylistFx,
})

sample({
  clock: initPlayerPageShowPlaying,
  source: {
    playlist: api.tvshows.$seriesPlaylist,
  },
  filter: ({ playlist }) => exists(playlist?.nextEpisode),
  fn: ({ playlist }, clockData) => {
    const params: SeriesPlaylistRecalculate = {
      nextTvShowId: Number(playlist?.nextTvShowId),
      nextSeasonId: Number(playlist?.nextSeasonId),
      nextEpisode: playlist?.nextEpisode,
      seasonsWithEpisodes: playlist?.seasonsWithEpisodes,
      episodes: playlist?.episodes,
      isLooped: playlist?.isLooped,
      isAutoplay: playlist?.isAutoplay,
      categoryId: clockData?.categoryId,

      tvShowId: clockData.tvShowId,
      seasonId: clockData.seasonId,
      episodeId: clockData.episodeId,
      showIndex: clockData.showIndex,
    }

    return params
  },
  target: api.tvshows.recalculateCurrentSeriesPlaylist,
})

//load playlist for TV show
export const $currentShowId = createStore<null | number>(null)
export const $tvShowPlaylist = combine(
  i18n.$t,
  api.tvshows.$showPlayList,
  ({ t }, playlist) => playlistFormatter(playlist, t)
)

sample({
  clock: initPlayerPageShowPlaying,
  source: $currentShowId,
  fn: (_, { tvShowId }) => tvShowId,
  target: $currentShowId,
})

sample({
  clock: $currentShowId,
  source: api.tvshows.getSeasonsWithEpisodesFx.pending,
  filter: (loading, currentShowId) => aye(currentShowId) && !loading,
  fn: (_, currentShowId) => {
    return { tvShowId: currentShowId! }
  },
  target: api.tvshows.getSeasonsWithEpisodesFx,
})
