import { createEvent, createStore, sample, split } from 'effector'
import { and, not } from 'patronum'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import { remote } from './defaults'
import * as config from './model'
import * as periodic from './update-periodic'
import * as realtime from './update-realtime'

export const init = createEvent()

const goPeriodic = createEvent()
const goRealtime = createEvent()

// allow periodic updates only on production
const $allowPeriodic = createStore(process.env.NODE_ENV === 'production')

// allow realtime updates only if flag `tria_EXPERIMENTAL_remoteConfigRealtimeEnabled` is set to true
const $allowRealtime = config.get(
  remote.tria_EXPERIMENTAL_remoteConfigRealtimeEnabled
)

// on init, decide which updates to enable depending on experimental realtime updates flag
// by default realtime updates are disabled
split({
  clock: [init, $allowRealtime],
  source: $allowRealtime,
  match: (is) => (is ? 'goRealtime' : 'goPeriodic'),
  cases: { goRealtime, goPeriodic },
})

// enable periodic updates on periodic updates go, but only if allowed
// also switch to periodic updates if realtime updates failed
sample({
  clock: [goPeriodic, realtime.disable],
  filter: $allowPeriodic, // this will disable periodic updates in development
  target: periodic.enable,
})

// disable realtime updates on periodic updates go
sample({
  clock: goPeriodic,
  target: realtime.disable,
})

// enable realtime updates, and disable periodic updates on realtime updates go
sample({
  clock: goRealtime,
  target: [realtime.enable, periodic.disable],
})

// fetch and activate new remote config on any update trigger from periodic or realtime updates
sample({
  clock: [periodic.update, realtime.update],
  filter: and(firebase.$ready, not(firebase.remote.fetchAndActivateFx.pending)),
  target: firebase.remote.forceFetchAndActivateFx,
})

// re-fetch and re-activate remote config on any user property change
// for now this will hit cache, this is current implementation,
// but maybe we should force fetch and activate on user property change ???
sample({
  clock: firebase.analytics.setUserPropertiesFx.done,
  filter: and(firebase.$ready, not(firebase.remote.fetchAndActivateFx.pending)),
  target: firebase.remote.fetchAndActivateFx,
})
