export class OptionsError extends Error {
  constructor(
    message: string,
    public override cause?: unknown
  ) {
    super(message)
    this.name = 'OptionsError'
  }
}

export class FetchError extends Error {
  constructor(
    message: string,
    public override cause?: unknown
  ) {
    super(message)
    this.name = 'FetchError'
  }
}

export class AbortError extends Error {
  constructor(
    message: string,
    public override cause?: unknown
  ) {
    super(message)
    this.name = 'AbortError'
  }
}

export class HttpError extends Error {
  constructor(
    public response: Response,
    public override cause?: unknown
  ) {
    super(response.statusText)
    this.name = 'HttpError'
  }
}

export class EmptyBodyError extends HttpError {
  constructor(
    response: Response,
    public override cause?: unknown
  ) {
    super(response)
    this.name = 'EmptyBodyError'
  }
}

export class BodyReadError extends Error {
  constructor(
    message: string,
    public override cause?: unknown
  ) {
    super(message)
    this.name = 'BodyReadError'
  }
}

export class RealtimeDisabledError extends Error {
  constructor(
    message: string,
    public override cause?: unknown
  ) {
    super(message)
    this.name = 'RealtimeDisabledError'
  }
}

export class RealtimeBackoffError extends Error {
  constructor(
    message: string,
    public override cause?: unknown
  ) {
    super(message)
    this.name = 'RealtimeBackoffError'
  }
}
