import type NpawPlugin from 'npaw-plugin-nwf'

// reexport NpawPlugin as type
export { type NpawPlugin }

// NpawPlugin options
// (ain't directly exported from npaw-plugin so using such construction here)
export type NpawPluginOptions = ConstructorParameters<typeof NpawPlugin>[1]

// BalancerOptions
// (ain't directly exported from npaw-plugin so using such construction here)
export type NpawBalancerOptions = Parameters<
  InstanceType<typeof NpawPlugin>['setBalancerOptions']
>[0]

// NPAW options, passed from application to the plugin
// should contain all necessary data for the plugin to work and send analytics
export type NpawPlayerOptions = {
  accountCode: string
  pluginOptions?: NpawPluginOptions
  balancerOptions?: NpawBalancerOptions
  analyticsOptions?: NpawAnalyticsDimensions
  videoOptions?: NpawVideoDimensions
}

//
// Analytics options / dimensions
// https://documentation.npaw.com/integration-docs/docs/options-analytics-js
//

// https://documentation.npaw.com/integration-docs/docs/options-analytics-js#network-options
export type NpawNetworkDimensions = {
  'network.ip'?: string // '1.1.1.1'
  'network.isp'?: string // 'ISPTest'
  'network.connectionType'?: string // 'cable/dsl'
}

// https://documentation.npaw.com/integration-docs/docs/options-analytics-js#device-options
// https://mapi.youbora.com/devices
export type NpawDeviceCodeDimensions = {
  'device.code': string // 'xbox360'
}

// https://documentation.npaw.com/integration-docs/docs/options-analytics-js#device-options
// full customised information
export type NpawDeviceFullDimensions = {
  'device.id': string // 'myUniqueDeviceId'
  'device.name': string // 'Android'
  'device.model': string // 's8'
  'device.brand': string // 'samsung'
  'device.type': string // 'smartphone'
  'device.osName': string // 'android'
  'device.osVersion': string // '8.1'
  'device.browserName': string // 'chrome'
  'device.browserVersion': string // '72'
  'device.browserType': string // 'mobile browser'
  'device.browserEngine': string // 'v8'
  'device.EDID'?: string // '123456' // as string or byte array
  'device.isAnonymous'?: boolean // false
}

// https://documentation.npaw.com/integration-docs/docs/options-analytics-js#device-options
export type NpawDeviceDimensions =
  | NpawDeviceCodeDimensions
  | NpawDeviceFullDimensions

// https://documentation.npaw.com/integration-docs/docs/options-analytics-js#user-options
export type NpawUserDimensions = {
  'user.name': string // 'My username'
  'user.email'?: string // 'My email'
  'user.type'?: string // 'My user type'
  'user.obfuscateIp'?: boolean // false
  'user.privacyProtocol'?: 'optin' | 'optout' // 'optout'
}

// https://documentation.npaw.com/integration-docs/docs/options-analytics-js#other-features
// prettier-ignore
export type NpawOtherDimensions = {
  'enabled'?: boolean // true
  'app.name': string // 'myCustomApp'
  'app.releaseVersion': string // '0.1-beta'
  'parse.cdnNode'?: boolean // true
  'parse.cdnNode.list'?: ('Akamai' | 'Amazon' | 'Cloudfront' | 'Level3' | 'Fastly' | 'Highwinds' | 'Telefonica' | 'Edgecast' | 'NosOtt' | 'Balancer')[]
  'parse.cdnNameHeader'?: string // 'x-cdn-forward'
  'parse.cdnNodeHeader'?: string // 'x-cdn-nodeid'
  'parse.manifest'?: boolean // false
  'parse.manifest.auth'?: object // { authorization: myToken }
  'offline'?: boolean // false
  'disableCookies'?: boolean // true
  'forceCookies'?: boolean // false
  'disableStorage'?: boolean // false
  'authToken'?: string // undefined
  'authType'?: string // 'Bearer'
}

// prettier-ignore
export type NpawAnalyticsDimensions =
  & NpawNetworkDimensions
  & NpawDeviceDimensions
  & NpawUserDimensions
  & NpawOtherDimensions
  & Partial<NpawVideoDimensions>

//
// Video content options / dimensions
// https://documentation.npaw.com/integration-docs/docs/options-video-js
//

// https://documentation.npaw.com/integration-docs/docs/options-video-js#media-metadata
// https://documentation.npaw.com/integration-docs/docs/options-video-js#dynamic-values
// -> by default everything apart from `content.metrics`, `content.totalBytes`, and `content.rendition`
//    does not accept the dynamic values. This needs to be activated first.
//    To do so, please get in touch with the support team at support@npaw.com
// prettier-ignore
export type NpawMediaDimensions = {
  'content.title': string // can be updated dynamically
  'content.program'?: string // can be updated dynamically
  'content.duration'?: number
  'content.isLive'?: boolean
  'content.fps'?: number
  'content.bitrate'?: number
  'content.totalBytes'?: number // can be updated dynamically
  'content.throughput'?: number
  'content.sendTotalBytes'?: boolean
  'content.rendition'?: string // can be updated dynamically
  'content.resource': string // can be updated dynamically
  'content.cdn'?: string // can be updated dynamically
  'content.cdnNode'?: string
  'content.cdnType'?: 1 | 2 | 3 | 4 // TCP_HIT / HIT: 1, TCP_MISS / MISS: 2, TCP_MEM_HIT: 3, TCP_IMS_HIT: 4
  'content.streamingProtocol'?: 'HDS' | 'HLS' | 'MSS' | 'DASH' | 'RTMP' | 'RTP' | 'RTSP' | 'MULTICAST' | 'DVB' | 'DVB-C' | 'DVB-T' | 'DVB-T2'
  'content.cdnName'?: string,
  'content.package'?: string
  'content.saga'?: string
  'content.tvShow'?: string
  'content.season'?: string
  'content.episodeTitle'?: string
  'content.channel'?: string
  'content.id': string
  'content.imdbId'?: string
  'content.gracenoteId'?: string
  'content.type'?: string // e.g. trailer, main, extra, clip, preview, ...
  'content.genre'?: string // e.g. romance, thriller, fantasy, ...
  'content.language'?: string // can be updated dynamically
  'content.subtitles'?: string // can be updated dynamically
  'content.contractedResolution'?: string
  'content.cost'?: number
  'content.price'?: number
  'content.playbackType'?: string // LIVE, VOD
  'content.drm'?: string
  'content.encoding.videoCodec'?: string
  'content.encoding.audioCodec'?: string
  'content.encoding.codecSettings'?: string
  'content.encoding.codecProfile'?: string
  'content.encoding.containerFormat'?: string
  'content.transactionCode'?: string
  'content.metrics'?: object // can be updated dynamically
  'content.metadata'?: object
  'content.customDimension.1'?: string // can be updated dynamically
  'content.customDimension.2'?: string // can be updated dynamically
  'content.customDimension.3'?: string // can be updated dynamically
  'content.customDimension.4'?: string // can be updated dynamically
  'content.customDimension.5'?: string // can be updated dynamically
  'content.customDimension.6'?: string // can be updated dynamically
  'content.customDimension.7'?: string // can be updated dynamically
  'content.customDimension.8'?: string // can be updated dynamically
  'content.customDimension.9'?: string // can be updated dynamically
  'content.customDimension.10'?: string // can be updated dynamically
  'content.customDimension.11'?: string
  'content.customDimension.12'?: string
  'content.customDimension.13'?: string
  'content.customDimension.14'?: string
  'content.customDimension.15'?: string
  'content.customDimension.16'?: string
  'content.customDimension.17'?: string
  'content.customDimension.18'?: string
  'content.customDimension.19'?: string
  'content.customDimension.20'?: string
  'content.customDimensions'?: { [key: string]: string }
}

// https://documentation.npaw.com/integration-docs/docs/options-video-js#ad-metadata
export type NpawAdDimensions = {
  'ad.blockerDetected'?: boolean
  'ad.ignore'?: boolean
  'ad.campaign'?: string
  'ad.creativeId'?: string
  'ad.provider'?: string
  'ad.resource'?: string
  'ad.title'?: string
  'ad.duration'?: number
  'ad.expectedPattern'?: object // { pre: [1], mid: [1, 1], post: [2] }
  'ad.givenAds'?: number
  'ad.breaksTime'?: number[]
  'ad.expectedBreaks'?: number
  'ad.givenBreaks'?: number
  'ad.metadata'?: object // { custom_field: "Custom value" }
  'ad.customDimension'?: { [key: string]: string }
  'ad.postRollTimeout'?: number
}

// https://documentation.npaw.com/integration-docs/docs/options-video-js#other-features
export type NpawOtherFeaturesDimensions = {
  'background.enabled'?: boolean // true
  'background.settings'?: '' | 'stop' | 'pause' // 'stop'
  'background.settings.android'?: '' | 'stop' | 'pause' // 'stop'
  'background.settings.desktop'?: '' | 'stop' | 'pause' // 'stop'
  'background.settings.iOS'?: '' | 'stop' | 'pause' // 'stop'
  'background.settings.playstation'?: '' | 'stop' | 'pause' // 'stop'
  'background.settings.tv'?: '' | 'stop' | 'pause' // 'stop'
  'content.isLive.noMonitor'?: boolean
  'content.isLive.noSeek'?: boolean
  'errors.fatal'?: string[] // ['123', 'errorcode']
  'errors.ignore'?: string[] // ['123', 'errorcode']
  'errors.nonFatal'?: string[] // ['123', 'errorcode']
  'forceInit'?: boolean // false
  'pause.ignoreSmallEvents'?: boolean // true
  'pendingMetadata'?: string[] // ['title', 'username'] // the name of the parameters in the request we are waiting for
  'referer'?: string // 'https://www.url.com'
  'referral'?: string // 'https://www.previous-url.com'
  'waitForMetadata'?: boolean // false
  'enableLegacyBufferBehaviour'?: boolean // false
}

// prettier-ignore
export type NpawVideoDimensions =
  & NpawMediaDimensions
  & NpawAdDimensions
  & NpawOtherFeaturesDimensions

//
// All dimensions merged
//

// prettier-ignore
export type NpawDimensions =
  & NpawAnalyticsDimensions
  & NpawVideoDimensions

// `LogLevel` enum from npaw-plugin package, which is declared, but not exists
export enum LogLevel {
  SILENT = 6, // No console outputs
  ERROR = 5, // Console will show errors
  WARNING = 4, // Console will show warnings
  NOTICE = 3, // Console will show notices (ie: life-cycle logs)
  DEBUG = 2, // Console will show debug messages (ie: player events)
  VERBOSE = 1, ///Console will show verbose messages (ie: Http Requests)
}

export type DRMProps = {
  licenseUrl: string
  headers?: { [key: string]: string }
  provider: string
}

export type LAProps = {
  WIDEVINE?: DRMProps
  PLAYREADY?: DRMProps
  FAIRPLAY?: DRMProps & { fpsCertificateUrl: string }
}

export interface IVideo {
  sourceUrl: string
  drm?: { la: LAProps }
  error?: number
  language?: string
  responsive?: boolean
  fluid?: boolean
  aspectRatio?: '16:9' | '4:3' | '1:1' | '9:16'
  controls?: boolean
  autoplay?: boolean | 'muted' | 'play' | 'any'
  fill?: boolean
  volume?: number
  playsinline?: boolean
  overrideNative?: boolean
  title?: string
  subtitle?: string
  isLiveUi?: boolean
}
