import { combine, sample } from 'effector'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import { defaults } from './defaults'
import { $config, $ready } from './model'
import * as updates from './updates'

export { remote } from './defaults'
export * as model from './model'

// setup remote config updates
sample({
  clock: $ready,
  filter: Boolean,
  target: updates.init,
})

// export firebase remote config values as static variable,
// in case it is needed somewhere in imperative logic.
// ⚠️ DO NOT ABUSE
export const config = defaults

// eslint-disable-next-line effector/no-watch
combine({
  config: $config,
  properties: firebase.analytics.$customParams,
}).watch(({ config: c, properties }) => {
  Object.assign(config, c, { __properties__: properties })
})
