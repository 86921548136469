import { DeviceType } from '../index.h'

const WEBP = 'webp'

export enum ImageDesktopSize {
  Background = '1920x1080',
  Horizontal = '896x605',
  Vertical = '900x1200',
  Logo = '170x100',
}

export enum ImageTabletSize {
  Background = '1280x1024',
  Horizontal = '577x390',
  Vertical = '383x517',
  Logo = '84x84',
}

export enum ImageMobileSize {
  Background = '589x888',
  Horizontal = '567x383',
  Vertical = '279x377',
  Logo = '84x84',
}

export enum ImageSearchParams {
  Blur = 'blur',
  Resize = 'resize',
  Format = 'format',
}

type ImageDimensions = `${ImageDesktopSize | ImageTabletSize | ImageMobileSize}`

type ImageSource = string | undefined

interface ResizeHelper {
  src: ImageSource
  dimensions: ImageDimensions
  isBlur?: boolean
}

let deviceType: DeviceType | null = null

export const setDeviceType = (device: DeviceType | null): void => {
  deviceType = device
}

const getDimensionsEnum = () => {
  switch (deviceType) {
    case DeviceType.Mobile:
      return ImageMobileSize
    case DeviceType.Tablet:
      return ImageTabletSize
    default:
      return ImageDesktopSize
  }
}

// TODO: FP-2788. It mutates url
export const setFormatHelper = (url: URL): URL => {
  url.searchParams.set(ImageSearchParams.Format, WEBP)
  return url
}

const replaceSize = ({
  src,
  dimensions,
  isBlur,
}: ResizeHelper & { src: string }): string => {
  const source = src.replace(/\{\{size\}\}/g, dimensions) // replaced to regexp to avoid interfering with Go templates

  const url = new URL(source)

  setFormatHelper(url)
  if (isBlur) url.searchParams.set(ImageSearchParams.Blur, String(isBlur))

  return url.toString()
}

// TODO: FP-2788. Need to refactor logic here
/**
 * @param {string} src - url for content resources
 * @param {string} template - template for replace
 * @returns {string|null} new url with replace {{size}}
 */
export const replaceSizeHelper = ({
  src,
  dimensions,
  isBlur,
}: ResizeHelper): ImageSource => {
  if (src) {
    try {
      const formatUrl = src.includes('https')
        ? src
        : src.replace('http', 'https')

      if (formatUrl.includes('{{size}}'))
        return replaceSize({ src: formatUrl, dimensions, isBlur })

      const url = new URL(formatUrl)

      setFormatHelper(url)
      url.searchParams.set(ImageSearchParams.Resize, dimensions)

      if (isBlur) url.searchParams.set(ImageSearchParams.Blur, String(isBlur))

      return url.toString()
    } catch (e) {
      console.error('Image url format is invalid', e)
      return
    }
  }
}

/**
 * @param {string} src - url for vods content resources
 * @param {boolean} isBlur - make a resized image blurry
 * @returns {function} return decorate function
 */
export const getBackgroundImage = (
  src: ImageSource,
  isBlur?: boolean
): ImageSource => {
  const dimensions = getDimensionsEnum()
  return replaceSizeHelper({ src, dimensions: dimensions.Background, isBlur })
}

export const getHorizontalImage = (
  src: ImageSource,
  isBlur?: boolean
): ImageSource => {
  const dimensions = getDimensionsEnum()
  return replaceSizeHelper({ src, dimensions: dimensions.Horizontal, isBlur })
}

export const getVerticalImage = (
  src: ImageSource,
  isBlur?: boolean
): ImageSource => {
  const dimensions = getDimensionsEnum()
  return replaceSizeHelper({ src, dimensions: dimensions.Vertical, isBlur })
}

export const getLogoImage = (
  src: ImageSource,
  isBlur?: boolean
): ImageSource => {
  const dimensions = getDimensionsEnum()
  return replaceSizeHelper({ src, dimensions: dimensions.Logo, isBlur })
}
