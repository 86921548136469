import { createEffect } from 'effector'

declare global {
  // disable `no-var` rule because this is not a variable declarations,
  // but a type definitions, and it works only with `var`

  /* eslint-disable no-var */
  var __console: Console
  /* eslint-enable no-var */
}

// save original console
globalThis.__console = globalThis.console

export class Logger {
  constructor(
    public readonly prefix = '',
    public readonly writer = globalThis.__console
  ) {}

  prepend = (data: unknown[]): unknown[] => {
    const prefixStyle = 'color:gray;font-weight:normal;font-size:0.8em;'
    const defaultStyle = 'color:inherit;font-weight:inherit;font-size:inherit;'
    if (data.length > 0) {
      if (typeof data[0] === 'string' && data[0].includes('%')) {
        data[0] = `%c${this.prefix}%c ${data[0]}`
        data.splice(1, 0, prefixStyle, defaultStyle)
      } else {
        data.unshift(`%c${this.prefix}`, prefixStyle)
      }
    }
    return data
  }

  log = (...data: unknown[]): void => {
    this.writer.log(...this.prepend(data))
  }

  dir = (...data: unknown[]): void => {
    this.writer.dir(...data) // `.dir` method doesn't support formatting
  }

  info = (...data: unknown[]): void => {
    this.writer.info(...this.prepend(data))
  }

  warn = (...data: unknown[]): void => {
    this.writer.warn(...this.prepend(data))
  }

  trace = (...data: unknown[]): void => {
    this.writer.trace(...this.prepend(data))
  }

  error = (...data: unknown[]): void => {
    this.writer.error(...this.prepend(data))
  }

  debug = (...data: unknown[]): void => {
    this.writer.debug(...this.prepend(data))
  }

  group = (...data: unknown[]): void => {
    this.writer.group(...this.prepend(data))
  }

  groupCollapsed = (...data: unknown[]): void => {
    this.writer.groupCollapsed(...this.prepend(data))
  }

  groupEnd = (): void => {
    this.writer.groupEnd()
  }

  // effector extension
  logFx = createEffect((data: unknown[]) => this.log(...data))
  dirFx = createEffect((data: unknown[]) => this.dir(...data))
  infoFx = createEffect((data: unknown[]) => this.info(...data))
  warnFx = createEffect((data: unknown[]) => this.warn(...data))
  traceFx = createEffect((data: unknown[]) => this.trace(...data))
  errorFx = createEffect((data: unknown[]) => this.error(...data))
  debugFx = createEffect((data: unknown[]) => this.debug(...data))
}

// default application logger instance
export const logger = new Logger('tria')
