import { logger } from '@/logger'
import {
  fetchAndActivate as fbFetchAndActivate,
  getRemoteConfig,
  type RemoteConfig,
} from '@setplex/firebase-remote-config'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { type FirebaseApp } from 'firebase/app'
import { env } from '~/shared/config/env'

export const init = createEvent<FirebaseApp>()
export const done = createEvent<void>()
export const setDefaults = createEvent<RemoteConfig['defaultConfig']>()

// creates firebase remote config
const getConfigFx = createEffect<FirebaseApp, RemoteConfig>(getRemoteConfig)
export const $firebaseConfig = createStore<RemoteConfig | null>(null)
  .on(getConfigFx.doneData, (_, config) => {
    config.settings.minimumFetchIntervalMillis =
      env.TRIA_CLIENT_FIREBASE_FETCH_INTERVAL
    return config
  })
  .on(setDefaults, (cfg, defaults) => {
    if (cfg) cfg.defaultConfig = defaults
    return cfg
  })

// get and activate remote config effect
export const fetchAndActivateFx = attach({
  source: $firebaseConfig,
  effect: async (config) => {
    if (config) {
      await fbFetchAndActivate(config)
    }
  },
})

// force fetch and activate remote config effect
// (by reducing fetch interval to minimum)
export const forceFetchAndActivateFx = attach({
  source: $firebaseConfig,
  effect: async (config) => {
    if (config) {
      // save original value of `minimumFetchIntervalMillis`
      // to enforce firebase cache invalidation
      const original = config.settings.minimumFetchIntervalMillis
      config.settings.minimumFetchIntervalMillis = 0
      try {
        await fetchAndActivateFx()
      } finally {
        // restore original value of `minimumFetchIntervalMillis`
        config.settings.minimumFetchIntervalMillis = original
      }
    }
  },
})

// init firebase remote config
sample({
  clock: init,
  target: getConfigFx,
})

// trigger done in any case after sucessfull or failed initialization
sample({
  clock: getConfigFx.finally,
  target: done,
})

// log error if remote config fetch failed
sample({
  clock: fetchAndActivateFx.failData,
  fn: (error) => ['firebase remote config fetch failed:', error],
  target: logger.errorFx,
})
