import { Logger } from '@/logger'
import { byKey, type Remote } from './defaults'

export const logger = new Logger('tria/remote-config')

/**
 * Print debug diff between two remote configs
 */
export function diff(a: Remote, b: Remote) {
  let changed = false

  // "smart" equality check function
  // tries to compare slightly different values, for example strings with json
  const eq = (x: unknown, y: unknown) => {
    if (typeof x !== typeof y) {
      return false
    }

    if (typeof x === 'number' && isNaN(x) && isNaN(y as number)) {
      return true
    }

    if (typeof x === 'string' && typeof y === 'string') {
      const xo =
        (x.includes('{') && x.includes('}')) ||
        (x.includes('[') && x.includes(']'))
      const yo =
        (y.includes('{') && y.includes('}')) ||
        (y.includes('[') && y.includes(']'))
      if (xo && yo) {
        try {
          return JSON.stringify(JSON.parse(x)) === JSON.stringify(JSON.parse(y))
        } catch (ignore) {
          // ignore
        }
      }
    }

    if (typeof x === 'object' && typeof y === 'object') {
      try {
        return JSON.stringify(x) === JSON.stringify(y)
      } catch (ignore) {
        // ignore
      }
    }

    return x === y
  }

  let key: keyof Remote
  for (key in a) {
    if (Object.hasOwnProperty.call(a, key) && byKey(key)) {
      if (!eq(a[key], b[key])) {
        changed = true
        logger.debug(
          `🚩 %c${key}%c updated from %c${typeof a[key] === 'string' ? a[key] || ' ' : a[key]}%c => %c${b[key]}`,
          'color:lightblue',
          'color:inherit',
          'color:gray;text-decoration:line-through',
          'color:inherit;text-decoration:inherit',
          'color:lightgreen'
        )
      }
    }
  }

  if (!changed) {
    logger.debug('🚩 %cnothing changed', 'color:gray')
  }

  return changed
}
