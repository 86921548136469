import { VersionFetch } from './version-fetch'

export class Invalidator extends VersionFetch {
  abortController: AbortController | undefined
  abortListeners: (() => void)[] = []

  async run(listener: (error: any, version: string) => void) {
    if (this.abortController) return // prevent multiple runs
    this.abortController = new AbortController()

    // get last known version from remote config cache
    const lastKnownVersion = await this.getLastKnownVersion() // with fallback to undefined
      .catch(() => undefined)

    try {
      for await (const version of this.fetch(
        this.abortController.signal,
        lastKnownVersion
      )) {
        listener(null, version)
      }
    } catch (error) {
      listener(error, '')
    }

    this.abort()
  }

  async abort() {
    this.abortController?.abort()
    for (const listener of this.abortListeners) {
      listener()
    }
  }

  onAbort(listener: () => void) {
    this.abortListeners.push(listener)
  }
}
