import { model as i18n } from '!/i18n'
import { model as router } from '!/router'
import { createProfilePopupModel } from '$/profiles-popup/create'
import { removeProfilePopupModel } from '$/profiles-popup/remove'
import { api } from '@setplex/tria-api'
import { combine, createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { MAX_NUMBER_OF_PROFILES, profilesModel } from '~/entities/profiles'
import { model as sessionModel } from '~/entities/session'
import { noop } from '~/shared/effector'

export const $profileAddTitle = combine(i18n.$t, ({ t }) => t('profile_create'))

export const $isMaxNumberOfProfiles = profilesModel.$optimisticProfiles.map(
  (profiles) => profiles.length === MAX_NUMBER_OF_PROFILES
)

export const selectedProfileId = createEvent<number>()
export const $selectedProfileId = createStore<number | null>(null)
  .on(selectedProfileId, (_, id) => id)
  .on(profilesModel.updateProfileActiveStateFx.done, (_, { params }) => params)
  .on(profilesModel.$optimisticProfiles, (id, profiles) =>
    profiles.find((profile) => profile.id === id) ? id : 0
  )
  .reset(api.session.signOutFx.doneData)

// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: $selectedProfileId,
  key: 'tria__selected_profile',
  fail: noop,
})

// close profiles create form when user have other created profiles
sample({
  clock: profilesModel.createProfileFx.doneData,
  source: {
    profiles: profilesModel.$optimisticProfiles,
  },
  filter: ({ profiles }) => profiles.length > 1,
  target: createProfilePopupModel.closeProfilesCreatePopup,
})

// close profiles create form when creating first profile
sample({
  clock: profilesModel.getActiveProfileFx.doneData,
  source: {
    profiles: profilesModel.$optimisticProfiles,
  },
  filter: ({ profiles }) => profiles.length === 1,
  target: createProfilePopupModel.closeProfilesCreatePopup,
})

sample({
  clock: profilesModel.updateProfileFx.doneData,
  target: createProfilePopupModel.closeProfilesCreatePopup,
})

sample({
  clock: profilesModel.removeProfileFx.doneData,
  target: removeProfilePopupModel.closeProfilesRemovePopup,
})

sample({
  clock: profilesModel.updateProfileActiveStateFx.doneData,
  target: router.refresh,
})

sample({
  clock: sessionModel.$session,
  target: [
    createProfilePopupModel.closeProfilesCreatePopup,
    removeProfilePopupModel.closeProfilesRemovePopup,
  ],
})
