import { noop } from '@/effector'
import { exists, nay } from '@/helpers'
import { api } from '@setplex/tria-api'
import { createEvent, sample } from 'effector'
import { persist } from 'effector-storage/local'

export interface IInitPlayerPageVodPlayingParams {
  categoryId?: number | null
  vodIndex?: number | null
  vodId: number
  eventId?: number
}
export const initPlayerPageVodPlaying =
  createEvent<IInitPlayerPageVodPlayingParams>()

sample({
  clock: initPlayerPageVodPlaying,
  fn: (clockData) => ({
    categoryId: Number(clockData.categoryId),
    vodId: Number(clockData.vodId),
    vodIndex: exists(clockData.vodIndex)
      ? Number(clockData.vodIndex)
      : undefined,
  }),
  target: api.vods.getRecommendedVodsFx,
})

// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: api.vods.$vodPlaylist,
  key: 'tria__vod-playlist',
  fail: noop,
})

sample({
  clock: initPlayerPageVodPlaying,
  source: api.vods.$vodPlaylist,
  filter: (vodPlaylist) => nay(vodPlaylist?.nextVod),
  fn: (_, clockData) => ({
    categoryId: Number(clockData.categoryId),
    vodId: clockData.vodId,
    vodIndex: Number(clockData.vodIndex),
    isLooped: false, // default value
    isAutoplay: false, // default value
  }),
  target: api.vods.generateVodPlaylistFx,
})

sample({
  clock: initPlayerPageVodPlaying,
  source: api.vods.$vodPlaylist,
  filter: (vodPlaylist) => Boolean(vodPlaylist?.nextVod),
  fn: (vodPlaylist, clockData) => ({
    isLooped: Boolean(vodPlaylist?.isLooped),
    isAutoplay: Boolean(vodPlaylist?.isAutoplay),
    nextVod: null,
    nextVods: null,
    currentVod: null,
    categoryId: clockData.categoryId,
    vodId: clockData.vodId,
    vodIndex: clockData.vodIndex,
  }),
  target: api.vods.recalculateCurrentValuesPlaylist,
})

export const updateVodPlaylist = createEvent<{
  isLooped?: boolean
  isAutoplay?: boolean
}>()

sample({
  clock: updateVodPlaylist,
  target: api.vods.updateVodPlaylist,
})
