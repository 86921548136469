import {
  type RemoteConfig,
  offConfigUpdateListener,
  onConfigUpdateListener,
} from '@setplex/firebase-remote-config'
import {
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
  split,
} from 'effector'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import { aye, F, nay, T } from '~/shared/helpers'
import { logger } from './lib'

export const enable = createEvent()
export const disable = createEvent()
export const update = createEvent()

// by default disabled
const $enabled = createStore(false).on(enable, T).on(disable, F)

// debug log realtime updates enabled state
sample({
  clock: $enabled,
  fn: (enabled) => ['🏎️ realtime updates enabled', enabled],
  target: logger.debugFx,
})

const listen = createEvent()
const unlisten = createEvent()

// start listening on remote config realtime updates
const onConfigUpdateListenerFx = createEffect((config: RemoteConfig) => {
  const update_ = scopeBind(update, { safe: true })
  const disable_ = scopeBind(disable, { safe: true })
  onConfigUpdateListener(config, (error, version) => {
    if (error) {
      logger.error('remote config update error:', error)
      disable_()
    } else {
      logger.debug('new remote config version:', version || '~')
      update_()
    }
  })
})

// stop listening on remote config realtime updates
const offConfigUpdateListenerFx = createEffect(
  (config?: RemoteConfig | null) => {
    offConfigUpdateListener(config)
  }
)

// on any change of $enabled value, start or stop listening on remote config updates
split({
  source: $enabled,
  match: (is) => (is ? 'listen' : 'unlisten'),
  cases: { listen, unlisten },
})

// listen on remote config realtime updates
sample({
  clock: listen,
  source: firebase.remote.$firebaseConfig,
  filter: aye,
  target: onConfigUpdateListenerFx,
})

// stop listening in case remote config is absent somehow (should not happen)
sample({
  clock: listen,
  source: firebase.remote.$firebaseConfig,
  filter: nay,
  fn: () => [
    'remote config realtime updates requested, but remote config is absent',
  ],
  target: [logger.errorFx, disable],
})

// stop listening on remote config realtime updates
sample({
  clock: [unlisten, disable],
  source: firebase.remote.$firebaseConfig,
  target: offConfigUpdateListenerFx,
})

sample({
  clock: onConfigUpdateListenerFx.failData,
  fn: (error) => ['remote config update listener error:', error],
  target: [logger.errorFx, disable],
})
