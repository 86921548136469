import { createEvent, createStore, sample } from 'effector'
import { delay } from 'patronum'
import { env } from '~/shared/config/env'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import { F, T } from '~/shared/helpers'
import { logger } from './lib'

export const enable = createEvent()
export const disable = createEvent()
export const update = createEvent()

// by default disabled
export const $enabled = createStore(false).on(enable, T).on(disable, F)

// debug log periodic updates enabled state
sample({
  clock: $enabled,
  fn: (enabled) => ['⏰ periodic updates enabled', enabled],
  target: logger.debugFx,
})

// set timeout to update remote config after every fetch and activate
// but triggers actual update only if periodic updates are enabled
sample({
  clock: delay({
    source: firebase.remote.fetchAndActivateFx.finally,
    timeout: env.TRIA_CLIENT_FIREBASE_FETCH_INTERVAL,
  }),
  filter: $enabled,
  target: update,
})
