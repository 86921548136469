import { RealtimeDisabledError } from './errors'
import { RepetitiveFetch } from './repetitive-fetch'

export class VersionFetch extends RepetitiveFetch {
  TEMPLATE_VERSION_KEY = 'latestTemplateVersionNumber'
  REALTIME_DISABLED_KEY = 'featureDisabled'

  lastVersion: string | undefined

  override version(version?: string) {
    return this.lastVersion ?? version
  }

  // logic largely inspired by android sdk:
  // https://github.com/firebase/firebase-android-sdk/blob/main/firebase-config/src/main/java/com/google/firebase/remoteconfig/internal/ConfigAutoFetch.java
  override async *fetch(signal: AbortSignal, version?: string) {
    this.lastVersion = version

    // multiple config update messages can be sent through this loop
    // each message comes in line by line as `chunk` and are accumulated together into `buffer`
    let buffer = ''
    for await (const chunk of super.fetch(signal, version)) {
      buffer += chunk

      // closing bracket indicates a full message has just finished
      if (chunk.includes('}')) {
        // strip beginning and ending of message
        const left = buffer.indexOf('{')
        const right = buffer.lastIndexOf('}')
        if (left === -1 || right === -1 || left >= right) {
          buffer = ''
        } else {
          buffer = buffer.slice(left, right + 1)
        }

        // skip empty or malformed messages
        if (buffer.length === 0) {
          continue
        }

        // parse message
        let message
        try {
          message = JSON.parse(buffer)
        } catch (error) {
          // message was mangled up and so it was unable to be parsed, but notify of this nonetheless,
          // because it there could be a new configuration that needs to be fetched
          yield '' // empty string means parsing error
        }

        // if realtime updates feature is disabled
        if (message && message[this.REALTIME_DISABLED_KEY] === true) {
          throw new RealtimeDisabledError(
            'Remote config realtime updates are unavailable'
          )
        }

        // if new version is available - yield it
        if (message && message[this.TEMPLATE_VERSION_KEY]) {
          this.lastVersion = message[this.TEMPLATE_VERSION_KEY]
          yield this.lastVersion as string
        }

        // reset buffer
        buffer = ''
      }
    }
  }
}
