import { model as config, remote } from '@/config'
import { noop } from '@/effector'
import { T, aye, nay } from '@/helpers'
import { logger } from '@/logger'
import { D } from '@mobily/ts-belt'
import { api, type INoraTranslationsFormat } from '@setplex/tria-api'
import { setDefaultOptions, type Locale } from 'date-fns'
import * as dateFnsLocales from 'date-fns/locale'
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
  type Effect,
  type Store,
} from 'effector'
import { persist } from 'effector-storage/local'
import { type InitOptions, type TFunction } from 'i18next'
import { and, not } from 'patronum'
import { i18n, initI18n, t } from '../i18next'
import { FALLBACK_LOCALE } from '../index.h'
import { capitalizeFormatter } from '../lib'
import { I18N_NORA_NAMESPACE } from './index.h'
//
// attach events to i18next events
// @see https://www.i18next.com/overview/api#events
//

export const languageChange = createEvent<{
  id: string
}>()

// fired after initialization
const initialized = createEvent<InitOptions>()
i18n.on('initialized', initialized)

// fired when changeLanguage got called
const languageChanged = createEvent<string>()
export const dateLanguageChanged = createEvent<string>()
i18n.on('languageChanged', languageChanged)

// fired on loaded resources
const loaded = createEvent<{
  [lng: string]: {
    [ns: string]: boolean
  }
}>()
i18n.on('loaded', loaded)

const allResLoaded = createEvent<boolean>()

sample({
  clock: loaded,
  filter: D.isNotEmpty,
  fn: T,
  target: allResLoaded,
})

// fired if loading resources failed (after the in-built retry algorithm)
const failedLoading = createEvent<{
  lng: string
  ns: string
  msg: string
}>()
i18n.on('failedLoading', (lng, ns, msg) => failedLoading({ lng, ns, msg }))

// fired on accessing a key not existing
// needs `saveMissing` set to `true`
const missingKey = createEvent<{
  lngs: readonly string[]
  ns: string
  key: string
  res: string
}>()
// i18n callback
// eslint-disable-next-line max-params
i18n.on('missingKey', (lngs, ns, key, res) =>
  missingKey({ lngs, ns, key, res })
)

// fired when resources got added
const added = createEvent<{
  lng: string
  ns: string
}>()

// fired when resources got removed
const removed = createEvent<{
  lng: string
  ns: string
}>()

const translationsFromNoraLoaded = createEvent<boolean>()
export const $languagesFromNora = createStore<Array<{
  id: string
  value: string
}> | null>(null)

const getTranslationsFromNoraFx = attach({ effect: api.i18n.getAllFx })

const translationsFormatter = (translations: Record<string, string>) => {
  try {
    const substring = new RegExp(/\d}/, 'g')
    const newSubstring = '}'
    return JSON.parse(
      JSON.stringify(translations).replaceAll(substring, newSubstring)
    )
  } catch (e) {
    logger.error('Translation key conversion error: ', e)
    return translations
  }
}

export const addTranslationsToNoraNSFx = createEffect(
  (translationsFormat: INoraTranslationsFormat) => {
    translationsFormat.forEach(({ translations, locale }) =>
      i18n.addResourceBundle(
        locale,
        I18N_NORA_NAMESPACE,
        translationsFormatter(translations)
      )
    )
  }
)

//
// stores
//

// store for a flag, indicating, that i18n is ready
export const $ready: Store<boolean> = and(
  restore(allResLoaded, false),
  restore(translationsFromNoraLoaded, false)
)

// store with `t` function
export const $t = createStore<{
  t: TFunction
}>({ t }) //
  .on(allResLoaded, () => ({ t })) // NOTE! resources not loaded on initialized -> t returns keys
  .on(dateLanguageChanged, () => ({ t }))
  .on(added, () => ({ t }))

export const $configIsNoraLocalization = config.get(
  remote.tria_isNoraLocalizationEnabled
)
export const $noraLocalizationEnabled = combine(
  $configIsNoraLocalization,
  config.$ready,
  (configIsNoraLocalization, isConfigReady) =>
    isConfigReady ? configIsNoraLocalization : null
)

// store with current locale language-picker
export const $locale = createStore<string>(FALLBACK_LOCALE) //
  .on(dateLanguageChanged, (_, locale) => locale)

const $localeChanged = createStore(false).on(languageChange, T)

const $remoteFbLanguage = config.get(remote.tria_defaultLanguage)
const $remoteNoraLanguage = createStore<string>('')
const $remoteLanguage = combine(
  {
    remoteFbLanguage: $remoteFbLanguage,
    remoteNoraLanguage: $remoteNoraLanguage,
    noraLocalizationEnabled: $noraLocalizationEnabled,
    i18nConfigReady: $ready,
  },
  ({
    remoteFbLanguage,
    remoteNoraLanguage,
    noraLocalizationEnabled,
    i18nConfigReady,
  }) => {
    if (noraLocalizationEnabled != null && i18nConfigReady) {
      return noraLocalizationEnabled ? remoteNoraLanguage : remoteFbLanguage
    }

    return null
  }
)

// handling empty array of languages from nora
// need to set fallback locale when there is no nora defaultLanguage
sample({
  clock: $languagesFromNora,
  source: $noraLocalizationEnabled,
  filter: (noraLocalizationEnabled, languagesFromNora) =>
    aye(noraLocalizationEnabled) &&
    languagesFromNora != null &&
    nay(languagesFromNora.length),
  fn: () => false,
  target: $localeChanged,
})

const updateLanguageFx = createEffect<string | null, void>((newLanguage) => {
  if (newLanguage != null) {
    const language = newLanguage || FALLBACK_LOCALE

    i18n.changeLanguage(language)
  }
})

const initI18nFx: Effect<
  {
    language?: string
  },
  void
> = createEffect(async ({ language }: { language?: string }) => {
  await initI18n(language || 'en')

  // Add custom formatters
  i18n.services.formatter?.add('capitalize', capitalizeFormatter)

  i18n.store.on('added', (lng, ns) => added({ lng, ns }))
  i18n.store.on('removed', (lng, ns) => removed({ lng, ns }))
})

sample({
  clock: added,
  filter: ({ ns }) => ns === I18N_NORA_NAMESPACE,
  fn: T,
  target: translationsFromNoraLoaded,
})

sample({
  clock: getTranslationsFromNoraFx.doneData,
  target: addTranslationsToNoraNSFx,
})

sample({
  clock: getTranslationsFromNoraFx.doneData,
  fn: (translations) =>
    translations.map(({ locale, languageName }) => ({
      id: locale,
      value: languageName,
    })),
  target: $languagesFromNora,
})

sample({
  clock: getTranslationsFromNoraFx.doneData,
  fn: (translations) =>
    translations.find(({ defaultLang }) => defaultLang)?.locale ?? '',
  target: $remoteNoraLanguage,
})

sample({
  clock: getTranslationsFromNoraFx.doneData,
  filter: (translations) => nay(translations.length),
  fn: T,
  target: translationsFromNoraLoaded,
})

sample({
  clock: [getTranslationsFromNoraFx.fail, addTranslationsToNoraNSFx.fail],
  fn: T,
  target: translationsFromNoraLoaded,
})

sample({
  // allows to init i18n with exact required language-picker
  clock: config.$ready,
  source: $locale,
  fn: (locale) => ({ language: locale }),
  target: initI18nFx,
})

const setDateFnsDefaultOptionsFx = createEffect((appLocale: string) => {
  const locale: Locale =
    dateFnsLocales[appLocale as keyof typeof dateFnsLocales] ??
    dateFnsLocales.enGB

  setDefaultOptions({ locale })

  return appLocale
})

sample({
  clock: languageChanged,
  target: setDateFnsDefaultOptionsFx,
})

sample({
  clock: setDateFnsDefaultOptionsFx.doneData,
  target: dateLanguageChanged,
})

sample({
  clock: $noraLocalizationEnabled,
  filter: (noraLocalizationEnabled) =>
    noraLocalizationEnabled != null && noraLocalizationEnabled,
  target: getTranslationsFromNoraFx,
})

sample({
  clock: $noraLocalizationEnabled,
  filter: (noraLocalizationEnabled) =>
    noraLocalizationEnabled != null && !noraLocalizationEnabled,
  fn: T,
  target: translationsFromNoraLoaded,
})

// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: $localeChanged,
  key: 'tria__lang_changed',
  fail: noop,
})

// we do not use scopes for now, so, ignore this rule
// eslint-disable-next-line effector/require-pickup-in-persist
persist({
  store: $locale,
  key: 'tria__lang',
  fail: noop,
})

sample({
  clock: $remoteLanguage,
  filter: not($localeChanged),
  target: updateLanguageFx,
})

sample({
  clock: languageChange,
  fn: ({ id }) => id,
  target: updateLanguageFx,
})
