import {
  AbortError,
  BodyReadError,
  EmptyBodyError,
  HttpError,
  RealtimeBackoffError,
} from './errors'
import { StreamFetch } from './stream-fetch'

export class RepetitiveFetch extends StreamFetch {
  // default and maximum backoff time in milliseconds
  private DEFAULT_BACKOFF = 10 * 1000 // 10 seconds
  private MAX_BACKOFF = 60 * 60 * 1000 // 1 hour

  // this method is overridden in child `VersionFetch` class
  // it parses version from fetched chunks, to be used in next fetch iteration
  version(version?: string) {
    return version
  }

  override async *fetch(signal: AbortSignal, version?: string) {
    let abort = false
    signal.addEventListener('abort', () => (abort = true))

    // initial backoff timeout and tries count
    let backoff = 0
    let tries = 0

    // count and limit of bad responses
    const maxBads = 10
    let bads = 0

    while (!abort) {
      try {
        await Promise.all([
          this.backoff(backoff, signal), // handle backoff before next fetch iteration
          this.visible(signal), // handle visibility state, do not fetch if page is hidden
          this.online(signal), // handle online state, do not fetch if navigator is offline
        ])
      } catch (error) {
        // if AbortError - this is normal case, just stop fetching and iterating
        if (error instanceof AbortError) {
          break
        }

        // otherwise - rethrow error
        throw error
      }

      try {
        yield* super.fetch(signal, this.version(version))

        // reset backoff and bad responses count after successful fetch
        backoff = this.DEFAULT_BACKOFF
        tries = 0
        bads = 0
      } catch (error) {
        // if AbortError - this is normal case, just stop fetching and iterating
        if (error instanceof AbortError) {
          break
        }

        // if HttpError - depending on a code it could be a normal case or not
        if (error instanceof HttpError) {
          const response = error.response

          // handle different HTTP status codes
          if (
            [
              408, // 408 Request Timeout
              413, // 413 Payload Too Large
              500, // 500 Internal Server Error
              502, // 502 Bad Gateway
              504, // 504 Gateway Timeout
            ].includes(response.status)
          ) {
            backoff = this.delay(++tries)
            continue
          }

          if (
            [
              429, // 429 Too Many Requests
              503, // 503 Service Unavailable
            ].includes(response.status)
          ) {
            const retryAfter = response.headers.get('Retry-After')
            if (retryAfter != null) {
              let after = Number(retryAfter)
              backoff = Number.isNaN(after)
                ? Date.parse(retryAfter) - Date.now() // Retry-After: <http-date>
                : after * 1000 // Retry-After: <delay-seconds>
            } else {
              backoff = this.delay(++tries)
            }
            continue
          }

          // otherwise - rethrow error (and stop fetching and iterating)
          throw error
        }

        // if BodyReadError|EmptyBodyError - try to skip this iteration and continue fetching
        // but rethrow error if limit reached
        if (error instanceof BodyReadError || error instanceof EmptyBodyError) {
          if (++bads >= maxBads) {
            throw error
          }
          backoff = this.delay(++tries)
          continue
        }

        // otherwise (FetchError) - rethrow error (and stop fetching and iterating)
        // can do nothing here, I think
        throw error
      }
    }
  }

  delay(count: number) {
    return this.DEFAULT_BACKOFF * 1.5 ** count // exponential backoff
  }

  /**
   * Awaits for a given delay
   */
  async backoff(ms: number, signal: AbortSignal) {
    if (ms > 0) {
      if (ms > this.MAX_BACKOFF) {
        throw new RealtimeBackoffError('Backoff timeout is too big')
      }

      return new Promise<void>((resolve, reject) => {
        const id = setTimeout(() => {
          clearTimeout(id)
          resolve()
        }, ms)
        signal.addEventListener('abort', () => {
          clearTimeout(id)
          reject(new AbortError('Delay aborted'))
        })
      })
    }
  }

  /**
   * Awaits for page to become visible
   */
  async visible(signal: AbortSignal) {
    if (document.visibilityState === 'hidden') {
      return new Promise<void>((resolve, reject) => {
        const listener = () => {
          if (document.visibilityState === 'visible') {
            document.removeEventListener('visibilitychange', listener)
            resolve()
          }
        }
        document.addEventListener('visibilitychange', listener)
        signal.addEventListener('abort', () => {
          document.removeEventListener('visibilitychange', listener)
          reject(new AbortError('Visibility handle aborted'))
        })
      })
    }
  }

  /**
   * Awaits for navigator to be in online state
   */
  async online(signal: AbortSignal) {
    if (!navigator.onLine) {
      return new Promise<void>((resolve, reject) => {
        const listener = () => {
          if (navigator.onLine) {
            window.removeEventListener('online', listener)
            resolve()
          }
        }
        window.addEventListener('online', listener)
        signal.addEventListener('abort', () => {
          window.removeEventListener('online', listener)
          reject(new AbortError('Online handle aborted'))
        })
      })
    }
  }
}
