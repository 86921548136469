import type { RemoteConfig } from '@firebase/remote-config'
import { OptionsError } from './errors'
import { Invalidator } from './invalidator'

// re-export all from default firebase remote-config package
// this is the same as package `firebase/remote-config` is doing
// see https://github.com/firebase/firebase-js-sdk/blob/main/packages/firebase/remote-config/index.ts
// and this allows to use this package as a drop-in replacement for the default one
export * from '@firebase/remote-config'

// map of remote configs to their invalidators
const invalidators = new Map<RemoteConfig, Invalidator>()

/**
 * Create and run remote config invalidator
 */
export function onConfigUpdateListener(
  config: RemoteConfig,
  listener: (error: any, version: string) => void
) {
  // prevent adding multiple invalidators for the same config
  if (invalidators.has(config)) return

  // get publically available options
  const { apiKey, appId, projectId } = config.app.options

  if (!apiKey) {
    throw new OptionsError('`apiKey` is missing')
  }

  if (!appId) {
    throw new OptionsError('`appId` is missing')
  }

  if (!projectId) {
    throw new OptionsError('`projectId` is missing')
  }

  // get private options, this is a hack...
  // remote config has three nested http clients:
  // `_client: CachingClient
  //   `client: RetryingClient
  //     `client: RestClient
  // see https://github.com/firebase/firebase-js-sdk/blob/4db3d3e7be8b435b523d23b0910958a495c09ad8/packages/remote-config/src/register.ts#L100-L115
  // all fields are private in terms of TypeScript, but we can access them, because this is JavaScript :)
  // but this could be changed in the future, so... be careful
  const { firebaseInstallations, namespace, sdkVersion } =
    (config as any)._client?.client?.client ?? {}

  if (
    !firebaseInstallations ||
    typeof firebaseInstallations !== 'object' ||
    !('getId' in firebaseInstallations) ||
    !('getToken' in firebaseInstallations) ||
    typeof firebaseInstallations.getId !== 'function' ||
    typeof firebaseInstallations.getToken !== 'function'
  ) {
    throw new OptionsError(
      '`firebaseInstallations` is missing or invalid',
      firebaseInstallations
    )
  }

  if (!namespace) {
    throw new OptionsError('`namespace` is missing')
  }

  if (!sdkVersion) {
    throw new OptionsError('`sdkVersion` is missing')
  }

  // get latest version from storage
  // this one is even more hacky, because it relays not only on a private field `_storage`,
  // but also on a package '@firebase/remote-config' patch, which saves version from response,
  // because original package just ignores it :(
  const versionHackyGetter = async () => {
    const response = await (
      config as any
    )._storage?.getLastSuccessfulFetchResponse?.()
    return response?.version
  }

  // create remote config invalidator
  const invalidator = new Invalidator(
    firebaseInstallations,
    sdkVersion,
    namespace,
    projectId,
    apiKey,
    appId,
    versionHackyGetter
  )

  // store invalidator to prevent adding multiple invalidators for the same config
  invalidators.set(config, invalidator)

  // run invalidation process
  invalidator.run(listener)
  invalidator.onAbort(() => {
    invalidators.delete(config) // asynchronously remove invalidator from the map
  })
}

/**
 * Stop and remove remote config invalidator
 */
export function offConfigUpdateListener(config?: RemoteConfig | null) {
  // if config is defined - abort only requested invalidatoion process
  if (config) {
    const invalidator = invalidators.get(config)
    if (invalidator) {
      invalidator.abort()
      invalidators.delete(config) // synchronously remove invalidator from the map
    }
  }

  // if config is not defined - abort all invalidation processes
  else {
    for (const invalidator of invalidators.values()) {
      invalidator.abort()
    }
    invalidators.clear() // synchronously clear the map
  }
}
